.active {
    color: var(--flo-primary) !important;
    font-weight: bold;
}

.indicator {
    position: absolute;
    top: 5px;
    left: -16px;
    bottom: 5px;
    width: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: var(--flo-primary);
}

.nav-item {
    position: relative;
}