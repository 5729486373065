@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --flo-pinkish: #F8F1F8;
  --flo-white: #FFFAFF;
  --flo-white-rgb: 255, 250, 255;
  --flo-navy: #222E50;
  --flo-navy-rgb: 34, 46, 80;
  --flo-purple: #9381FF;
  --flo-light-purple: #9381FF40;
  --flo-primary: #C1666B;
  --flo-primary-rgb: 193, 102, 107;
  --flo-green: #3CD2A5;
  --flo-gray: #E0E0E0;
  --flo-dark: #19213A;
  --flo-orange: #F55536;

  --flo-bg: var(--flo-white);
  --flo-content-bg: var(--flo-pinkish);
  --flo-text-color: var(--flo-navy);
  --flo-text-color-rgb: var(--flo-navy-rgb);
}

body {
  font-family: 'Inter', sans-serif !important;
  background: var(--flo-bg);
}

.flo-style {
  background: var(--flo-bg);
  color: var(--flo-text-color);
}

.flo-logo {
  font-size: 36px;
  font-weight: 700;
  color: var(--flo-primary) !important;
}

.flo-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--flo-text-color) !important;
}

.flo-header {  
  font-size: 16px;
  font-weight: 700;
  color: var(--flo-text-color) !important;
}

.nav-link.flo-header:hover {
  opacity: 60%;
}

.flo-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--flo-text-color) !important;
}

.nav-link.flo-text:hover {
  opacity: 60%;
}

.flo-highlight {
  color: var(--flo-primary) !important;
}

.flo-content {
  background: var(--flo-content-bg);
}

.flo-border {
  border: 1px solid var(--flo-light-purple);
}

.flo-border-top {
  border-top: 1px solid var(--flo-light-purple);
}

.flo-border-left {
  border-left: 1px solid var(--flo-light-purple);
}

.flo-border-right {
  border-right: 1px solid var(--flo-light-purple);
}

.flo-border-bottom {
  border-bottom: 1px solid var(--flo-light-purple);
}

.btn-flo-primary {
  background: var(--flo-purple);
  color: var(--flo-white);
}

.btn-flo-primary:hover {
  color: var(--flo-navy);
}

.btn-outline-flo-primary {
  border: 1px solid var(--flo-purple);
  color: var(--flo-purple);
}

.btn-outline-flo-primary:hover {
  color: var(--flo-text-color);
  background: var(--flo-light-purple);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  height: 100%;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/**
* Hide the number input arrows.
* See https://stackoverflow.com/questions/71296535/how-to-remove-arrow-on-input-type-number-with-tailwind-css
* for more information.
*/
.hide-arrows[type='number']::-webkit-inner-spin-button,
.hide-arrows[type='number']::-webkit-outer-spin-button,
.hide-arrows[type='number']{
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}
