.spinner {
    animation: spin 1.25s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    animation-play-state: inherit;
    border: solid 4px rgba(var(--flo-text-color-rgb),0.1);
    border-top-color: var(--flo-primary);
    border-radius: 50%;
    content: "";
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}

.spinner-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}


